import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"
import specialCharacterReplace from "../../utils/special-character-replace"

import SearchFilter from "../../components/parts/chart/search-filter"
import ImageBox from "../../components/parts/image-box"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      s09_1_1_1 : false,
      s09_1_1_2 : false,
      s09_1_1_3 : false,
      s09_1_1_4 : false
    }

    this.searchInit = {
      filter1Init: 4,
      filter2Init: 0,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : true
    })
  }
  closeModal(e) {
    const name = e.currentTarget.getAttribute('data-name')
    this.setState({
      [name] : false
    })
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
        <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>セメント生産量見通し</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s09_1_1_1"}>
                <img src={'../../images/chart/s09-1-1-1.png'} alt="セメント生産量見通し" width={559} />
              </button>
              <ImageBox
                open={this.state.s09_1_1_1}
                close={this.closeModal}
                name={"s09_1_1_1"}
                src={'/images/chart/s09-1-1-1.png'}
                alt="セメント生産量見通し"
                width={1260}
                height={572} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/technology-roadmap-low-carbon-transition-in-the-cement-industry" target="_blank" rel="noreferrer noopener">IEA, ”Technology Roadmap - Low-Carbon Transition in the Cement Industry"</a> （2018年4月）</p>
              </div>
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>セメント・コンクリート分野におけるネットゼロ達成に向けた排出パス</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s09_1_1_2"}>
                <img src={'../../images/chart/s09-1-1-2.png'} alt="セメント・コンクリート分野におけるネットゼロ達成に向けた排出パス" width={559} />
              </button>
              <ImageBox
                open={this.state.s09_1_1_2}
                close={this.closeModal}
                name={"s09_1_1_2"}
                src={'/images/chart/s09-1-1-2.png'}
                alt="セメント・コンクリート分野におけるネットゼロ達成に向けた排出パス"
                width={1260}
                height={1040} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://gccassociation.org/concretefuture/wp-content/uploads/2021/10/GCCA-Concrete-Future-Roadmap-Document-AW.pdf" target="_blank" rel="noreferrer noopener">Global Cement and Concrete Association, ”CONCRETE FUTURE The GCCA 2050 Cement and Concrete Industry Roadmap for Net Zero Concrete "</a>（2021年10月）</p>
              </div>
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>セメント・コンクリート分野の2050年ネットゼロに向けたCO2排出削減技術</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s09_1_1_3"}>
                <img src={'../../images/chart/s09-1-1-3.png'} alt="セメント・コンクリート分野の2050年ネットゼロに向けたCO2排出削減技術" width={559} />
              </button>
              <ImageBox
                open={this.state.s09_1_1_3}
                close={this.closeModal}
                name={"s09_1_1_3"}
                src={'/images/chart/s09-1-1-3.png'}
                alt="セメント・コンクリート分野の2050年ネットゼロに向けたCO2排出削減技術"
                width={1260}
                height={730} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://gccassociation.org/concretefuture/wp-content/uploads/2021/10/GCCA-Concrete-Future-Roadmap-Document-AW.pdf" target="_blank" rel="noreferrer noopener">Global Cement and Concrete Association, ”CONCRETE FUTURE The GCCA 2050 Cement and Concrete Industry Roadmap for Net Zero Concrete "</a>（2021年10月）</p>
                <p className={chartStyles.chartExplain}>本グラフは「セメント・コンクリート分野におけるCO2排出量見通し」の右棒グラフの詳細内訳を示すもの。</p>
              </div>
            </div>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>セメント分野におけるエネルギー消費・CO2排出関連指標見通し</h2>
              </div>
              <button className={styles.modalLink} onClick={this.openModal} data-name={"s09_1_1_4"}>
                <img src={'../../images/chart/s09-1-1-4.png'} alt="セメント分野におけるエネルギー消費・CO2排出関連指標見通し" width={559} />
              </button>
              <ImageBox
                open={this.state.s09_1_1_4}
                close={this.closeModal}
                name={"s09_1_1_4"}
                src={'/images/chart/s09-1-1-4.png'}
                alt="セメント分野におけるエネルギー消費・CO2排出関連指標見通し"
                width={1260}
                height={858} />
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/technology-roadmap-low-carbon-transition-in-the-cement-industry" target="_blank" rel="noreferrer noopener">IEA, ”Technology Roadmap - Low-Carbon Transition in the Cement Industry"</a>（2018年4月）</p>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`